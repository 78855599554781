<template>
  <v-dialog v-model="dialog" width="800px">
    <template v-slot:activator="{}">
      <v-btn dense height="30" block elevation="0" @click="open">Editar</v-btn>
    </template>
    <v-card>
      <v-card-title>Información anexa para el usuario </v-card-title>
      <div class="close">
        <v-btn @click="dialog = false" icon small>
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </div>
      <v-stepper alt-labels v-model="step" style="background: transparent">
        <v-stepper-header>
          <div class="my-auto pb-10">
            <v-btn
              @click="step--"
              :disabled="step == 1"
              elevation="0"
              icon
              large
            >
              <v-icon>mdi-chevron-left</v-icon>
            </v-btn>
          </div>
          <v-spacer />
          <draggable v-model="headers" class="draggable">
            <div class="d-inline-block" v-for="(h, i) in headers" :key="i">
              <v-stepper-step
                :step="1 + i"
                @click="step = i + 1"
                class="pointer"
                ghost-class="ghost"
              >
                <div class="mt-0">
                  <v-text-field
                    v-model="h.title"
                    hide-details
                    class="pt-0"
                    style="margin: auto; max-width: 80%"
                  ></v-text-field>
                  <div class="close">
                    <v-btn
                      elevation="0"
                      height="30"
                      class="mt-2 mr-4"
                      @click="del(i)"
                      icon
                      small
                      v-if="i != 0"
                    >
                      <v-icon>mdi-close</v-icon>
                    </v-btn>
                  </div>
                </div>
              </v-stepper-step>
            </div>
          </draggable>

          <v-spacer />
          <div class="my-auto pb-10 pl-5">
            <v-btn icon x-large @click="add()">
              <v-icon size="36">mdi-plus-circle</v-icon>
            </v-btn>
            <div>Añadir</div>
          </div>
          <div class="my-auto pb-10">
            <v-btn
              @click="step++"
              :disabled="step == headers.length"
              elevation="0"
              icon
              large
            >
              <v-icon>mdi-chevron-right</v-icon>
            </v-btn>
          </div>
        </v-stepper-header>
      </v-stepper>
      <v-card-text>
        <v-row>
          <v-col>
            <editor
              v-if="editor"
              :api-key="apiKey"
              v-model="headers[step - 1].text"
              :init="init"
            />
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-dialog width="800px" v-model="dialog_preview">
          <template v-slot:activator="{ on }">
            <v-btn
              v-on="on"
              @click="openPreview"
              style="
                width: 120px !important;
                color: #363533;
                height: 25px;
                padding-top: 10px !important;
              "
              elevation="0"
            >
              <v-icon style="margin-right: 5px" size="14px">$eye</v-icon>
              Vista previa
            </v-btn>
          </template>
          <v-card class="py-0">
            <Piercing
              ref="form"
              v-if="sub_service.parent_id == 2"
              :pop_up="true"
              @close="dialog_preview = false"
            />
            <Laser
              ref="form"
              v-else-if="sub_service.parent_id == 3"
              :pop_up="true"
              @close="dialog_preview = false"
            />
            <Microblading
              ref="form"
              v-else-if="sub_service.parent_id == 4"
              :pop_up="true"
              @close="dialog_preview = false"
            />
            <Tattoo
              ref="form"
              v-else
              :pop_up="true"
              @close="dialog_preview = false"
            />
          </v-card>
        </v-dialog>
        <v-btn
          @click="save()"
          style="
            width: 100px !important;
            color: #363533;
            height: 25px;
            padding-top: 10px !important;
          "
          elevation="0"
        >
          <v-icon style="margin-right: 5px" size="14px">$save</v-icon>
          {{ $t("save", { name: $t("") }) }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import { mapActions } from "vuex";
export default {
  name: "ConsentFormSubServiceConfiguration",
  props: ["sub_service"],

  watch: {
    "headers.length": {
      handler() {
        if (this.dialog) {
          this.showEditor();
        }
      },
      deep: true,
    },
    step: {
      handler() {
        if (this.dialog) {
          this.showEditor();
        }
      },
      deep: true,
    },
  },
  data() {
    return {
      dialog: false,
      dialog_preview: false,
      headers: [],
      step: 1,
      editor: false,
      init: {
        height: 200,
        content_style: "* {color: white}",
        menubar: false,
        selector: "#instance3",
        plugins: [
          "advlist autolink lists link image charmap print preview anchor",
          "searchreplace visualblocks code fullscreen",
          "insertdatetime media table paste code help wordcount",
        ],
        toolbar:
          "bold italic underline " +
          "undo redo | fontsizeselect formatselect| forecolor backcolor |" +
          "alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | ",
      },
    };
  },
  components: {
    draggable: () => import("vuedraggable"),
    Editor: () => import("@tinymce/tinymce-vue"),
    Tattoo: () => import("@/views/appointments/ConsentFormView"),
    Piercing: () => import("@/views/appointments/ConsentFormPiercerView"),
    Laser: () => import("@/views/appointments/ConsentFormLaserView"),
    Microblading: () =>
      import("@/views/appointments/ConsentFormMicroblandingView"),
  },
  methods: {
    ...mapActions("subservices", ["consentFormSubService"]),
    save() {
      console.log("SAVE");
      this.consentFormSubService({
        service_id: this.sub_service.parent_id,
        sub_service_id: this.sub_service.id,
        consent_forms: this.headers,
      }).then(() => {
        this.$alert(this.$t("save_ok"));
        this.dialog = false;
        this.$emit("update");
      });
    },
    open() {
      if (this.sub_service.consent_form)
        this.headers = this.sub_service.consent_form;
      else this.headers = [{ title: "Información personal", text: null }];
      this.dialog = true;
      this.showEditor();
    },
    openPreview() {
      setTimeout(() => {
        this.$refs.form.sub_service_annex = this.headers;
        this.$refs.form.e1 = 1;
        this.$refs.form.e2 = 1;
      }, 1000);
    },
    showEditor() {
      this.editor = false;
      setTimeout(() => {
        this.editor = true;
        console.log("EDITOR");
      }, 100);
    },
    add() {
      this.headers.push({ title: null, text: null });
    },
    del(index) {
      this.headers.splice(index, 1);
      while (this.step > this.headers.length) {
        console.log(this.step, this.headers.length);
        this.step--;
      }
    },
  },
  computed: {
    apiKey() {
      return process.env.VUE_TINYMCE_API_KEY;
    },
  },
};
</script>

<style lang="sass">
.draggable
  max-width: 77%
  overflow-x: auto
  white-space: nowrap
  .v-stepper__step
    padding: 16px
</style>
